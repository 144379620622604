import axios from "axios";
const API = "auction-center";

export default {
  async get() {
    return await axios
      .get(API)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  create(item) {
    return axios
      .post(API, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  delete(id) {
    return axios
      .delete(API + `/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  edit(id, item) {
    return axios
      .patch(API + `/${id}`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
